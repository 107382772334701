<script>
import {ref, computed} from "vue";
import { scaleLinear } from "d3-scale";
import {format} from "d3-format"

import FileSaver from "file-saver";

import Canvg, {
    presets
} from 'canvg';

import CategoryList from '@/components/CategoryListUI.vue';
import Tooltip from "@/components/TooltipUI.vue";

import {pyramidCategory} from "@/graphsConfig.js";

const {
  margin, 
  xLabel, 
  yLabel,
  cLabel,
  barHeight,
  colors,
  width,
  background,
  ticks
} = pyramidCategory;

const f = format(",")
const center = (width / 2) + margin.left;

export default {
  props : ['data', 'config'],
  setup(props){

    const getFilterOptions = filter => {
      return [...new Set(props.data.map( d => d[filter]))].sort()
    }

    const updateValues = (e, i) => {
      values.value[i] = e.target.value;
    }

    const onHover = (e, data) => {
      showTooltip.value = true;
      clientX.value     = e.clientX + 5;
      clientY.value     = e.clientY -20;
      tooltipItem.value = data;
    }

    const onMove = e => {
      showTooltip.value = true;
      clientX.value = e.clientX + 5;
      clientY.value = e.clientY -20;
    }

    const filters    = computed( () => {
      return props.config.filters.map(d => {
        return {
          name : d,
          items : getFilterOptions(d)
        }
      }) 
    });

    const mappedData = computed( () => {
      let filters = props.config.filters;
      
      let data = props.data.map(d => {
        let item = {};
        item.id             = new Date().valueOf();
        item.category       = d[yColumn.value];
        item.value          = d[xColumn.value];
        item[cColumn.value] = d[cColumn.value];
        for(let f of filters){
          item[f] = d[f];
        }
        item.data     = d;
        return item;
      });
      return data;
    });

    const currentData = computed( () => {
      let curr =  mappedData.value.filter(d => {
        let check = [];
        for(let i = 0; i< filters.value.length; i++){
          check.push(d[filters.value[i].name] == values.value[i])
        }
        return check.filter(d => d).length == filters.value.length;
      })

      return categories.value.map( d => curr.filter(e => e[cColumn.value] == d))
    })

    const xColumn    = computed( () => props.config.fields.find(d => d.label == xLabel).column );
    const yColumn    = computed( () => props.config.fields.find(d => d.label == yLabel).column );
    const cColumn    = computed( () => props.config.fields.find(d => d.label == cLabel).column );
    const categories = computed( () => [...new Set(props.data.map( d => d[cColumn.value]))].sort() );

    const yItems =  computed( () => {
      return [...new Set( props.data.map( d => d[yColumn.value]) )]
    });

    const height  = computed( () => {
      return margin.top + margin.bottom + (barHeight * yItems.value.length)
    });

    const xScaleA = computed( () => {
      let curr = currentData.value.flat().map(d => d.data);

      return scaleLinear()
        // .domain([0,Math.max(... props.data.map( d => +d[xColumn.value])) ])
        .domain([0,Math.max(... curr.map( d => +d[xColumn.value])) ])
        .rangeRound([center, margin.left])
    });

    const xScaleB = computed( () => {
      let curr = currentData.value.flat().map(d => d.data);
      return scaleLinear()
        // .domain([ 0,Math.max(... props.data.map( d => +d[xColumn.value])) ])
        .domain([ 0,Math.max(... curr.map( d => +d[xColumn.value])) ])
        .rangeRound([center, width - margin.right])
    });

    const values      = ref( filters.value.map( d => d.items[0]) );
    const showTooltip = ref(false);
    const clientX     = ref(0);
    const clientY     = ref(0);
    const tooltipItem = ref(null);

    const svg    = ref(null);

    const saveImage = async () => {
      const canvas = new OffscreenCanvas(width, height.value);
      const ctx    = canvas.getContext('2d');
      const v      = await Canvg.fromString(ctx, svg.value.outerHTML, presets.offscreen());

      await v.render();

      const blob = await canvas.convertToBlob();
      const pngUrl = URL.createObjectURL(blob);

      FileSaver.saveAs(pngUrl, "image.png")
    }

    return {
      // const
      width,
      height,
      colors,
      background,
      ticks,
      margin,
      barHeight,
      center,

      // refs
      values,
      showTooltip,
      clientX,
      clientY,
      tooltipItem,
      svg,

      // computed
      xScaleA,
      xScaleB,
      yItems,
      filters,
      currentData,
      categories,

      // methods
      updateValues,
      onHover,
      onMove,
      saveImage,
      f
    }
  },
  components : {
    CategoryList,
    Tooltip
  }
}
</script>
<template>
  <div class="sg_viz">
    <div class="row mt-4">
      <!-- the filters -->
      <div v-if="filters.length" class="col-sm-8">
        <ul class="row sg_filters">
          <li v-for="(filter, i) of filters" :key="`filter-${i}`" class="col">
            {{filter.name}}
            <select @change="e => updateValues(e, i)">
              <option v-for="(opt,j) of filter.items" :key="`fil-${opt}-${i}-${j}`">
                {{opt}}
              </option>
            </select>
          </li>
        </ul>
      </div>
      <!-- category list -->
      <div class="col-sm-3 offset-sm-1 mt-3">
        <category-list :categories="categories" :colors="colors" :current="currentData" />
      </div>
    </div>
<div class="sg_dataviz">
    <svg ref="svg" xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny" width="100%" height="100%" :viewBox="`0 0 ${width} ${height}`" stroke-linecap="round" stroke-linejoin="round" class="sg_svg">
      <rect width="100%" height="100%" :fill="background" />

      <!-- x ticks (A) -->
      <!-- <g v-for="(tick, i) of xScaleA.ticks(5)" :transform="`translate(${xScaleA(tick)}, 0)`" :key="`x-tick-${i}`">
        <line x1="0" y1="0" x2="0" :y2="height - margin.bottom" :stroke="ticks.color" :fill-opacity="ticks.opacity" />
      </g> -->
      <!-- x ticks (B) -->
      <!-- <g v-for="(tick, i) of xScaleB.ticks(5)" :transform="`translate(${xScaleB(tick)}, 0)`" :key="`x-tick-${i}`">
        <line x1="0" y1="0" x2="0" :y2="height - margin.bottom" :stroke="ticks.color" :fill-opacity="ticks.opacity" />
      </g> -->
      
      <!-- bars -->
      <g v-for="(label, i) of yItems" :key="`y-label-${i}`" :transform="`translate(0, ${margin.top + (barHeight * i)})`">
        <!-- tags -->
        <text x="2" y="0" text-anchor="start" alignment-baseline="hanging">{{label}}</text>
      </g>
      <!-- A bars -->
      <rect v-for="(item, i) of currentData[0]" 
        :key="`y-bar-0-${i}`" 
        :x="xScaleA(item.value)" 
        :y="margin.top + (barHeight * i)" 
        :width="center - xScaleA(item.value)" 
        :height="barHeight - 3"
        :fill="colors[0]"
        @mouseover="e => onHover(e, item)" 
        @mousemove="onMove" 
        @mouseout="showTooltip = false" />
      <!-- B bars -->
      <rect v-for="(item, i) of currentData[1]" 
        :key="`y-bar-0-${i}`" 
        :x="center" 
        :y="margin.top + (barHeight * i)" 
        :width="xScaleB(item.value) - center" 
        :height="barHeight - 3"
        :fill="colors[1]"
        @mouseover="e => onHover(e, item)" 
        @mousemove="onMove" 
        @mouseout="showTooltip = false" />

      <!-- xScaleAxis -->
      <g :transform="`translate(0, ${height - margin.bottom})`">
        <!-- ticks A -->
        <g v-for="(tick, i) of xScaleA.ticks(5)" :transform="`translate(${xScaleA(tick)}, 0)`" :key="`x-tick-${i}`">
          <line x1="0" y1="0" x2="0" :y2="3" stroke="black" />
          <text x="0" y="5" text-anchor="middle" alignment-baseline="hanging">{{f(tick)}}</text>
        </g>
        
        <!-- ticks B -->
        <g v-for="(tick, i) of xScaleB.ticks(5)" :transform="`translate(${xScaleB(tick)}, 0)`" :key="`x-tick-${i}`">
          <line x1="0" y1="0" x2="0" y2="3" stroke="black" />
          <text x="0" y="5" text-anchor="middle" alignment-baseline="hanging">{{f(tick)}}</text>
        </g>
        
        <!-- Axis -->
        <line
          :x1="margin.left"
          y1="0"
          :x2="width - margin.right"
          y2="0"
          stroke="black" />
      </g>
    </svg>
    </div>
    <p class="mt-3"><button @click.prevent="saveImage" class="btn_image">Guardar imagen <b class="sg_i_image"></b></button></p>

    <tooltip  :clientX="clientX" :clientY="clientY" :show="showTooltip" :labels="config.labels" :item="tooltipItem" />
  </div>
</template>