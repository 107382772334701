<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
// import {select} from "d3-selection";
import { format } from "d3-format";
import { scaleBand, scaleLinear } from "d3-scale";
// import {/*axisBottom, */axisLeft} from "d3-axis";
import FileSaver from "file-saver";

import Canvg, { presets } from "canvg";

import Tooltip from "@/components/TooltipUI.vue";

import { barsCategory } from "@/graphsConfig.js";

// import getFilters from "@/composables/filters.js";
import getTooltip from "@/composables/tooltip.js";
// import getData from "@/composables/data.js";

const {
  margin,
  height,
  xLabel,
  yLabel,
  minWidth,
  barWidth: bw,
  color,
  background,
  ticks,
} = barsCategory;

const f = format(",");

export default {
  props: ["data", "config"],
  setup(props) {
    const store = useStore();
    const containter = ref(null);

    const updateValues = (e, i) => {
      values.value[i] = e.target.value;
      updateScales();
    };

    const updateScales = () => {
      // xAxis.value = axisBottom(xScale.value).tickSizeOuter(0);
      // yAxis.value = axisLeft(yScale.value).ticks(null, "s");
      // gx.value    = select(svg.value).append("g").attr("transform", `translate(0, ${height - margin.bottom})`);
      // gy.value    = select(svg.value).append("g").attr("transform", `translate(${margin.left}, 0)`);
      // gx.value.call(xAxis.value);
      // gy.value.call(yAxis.value)
    };

    const {
      onHover,
      onMove,
      showTooltip,
      clientX,
      clientY,
      tooltipItem,
    } = getTooltip();

    // const {filters} = getFilters(props);
    const filters = computed(() => {
      return props.config.filters.map((d) => {
        return {
          name: d,
          items: [...new Set(props.data.map((e) => e[d]))].sort(),
        };
      });
    });

    const config = computed(() => props.config);
    const xColumn = computed(
      () => config.value.fields.find((d) => d.label == xLabel).column
    );
    const yColumn = computed(
      () => config.value.fields.find((d) => d.label == yLabel).column
    );

    const xitems = computed(() => {
      let curr = currentData.value.flat().map((d) => d.data);
      return [...new Set(curr.map((d) => d[xColumn.value]))];
    });

    const barWidth = computed(() => {
      const items = xitems.value.length;
      const containerWidth = containter.value
        ? containter.value.offsetWidth
        : 0;
      const minW = containerWidth > minWidth ? containerWidth : minWidth;
      if (!items) return bw;

      const total = items * bw;
      return total > minW ? bw : minW / items;
    });

    const values = ref(filters.value.map((d) => d.items[0]));

    // const {barsMappedData: mappedData} = getData(props, filters, null, xColumn, yColumn, values)
    const mappedData = computed(() => {
      let filters = config.value.filters;
      let data = props.data.map((d) => {
        let item = {};
        item.id = new Date().valueOf();
        item.category = d[xColumn.value];
        item.value = d[yColumn.value];
        for (let f of filters) {
          item[f] = d[f];
        }
        item.data = d;
        return item;
      });
      return data;
    });
    // const {currentData} = getData(props, filters, mappedData, xColumn, yColumn, values)

    const currentData = computed(() => {
      let res = mappedData.value.filter((d) => {
        let check = [];
        for (let i = 0; i < filters.value.length; i++) {
          check.push(d[filters.value[i].name] == values.value[i]);
        }
        return check.filter((d) => d).length == filters.value.length;
      });

      return res;
    });

    const width = computed(() => {
      return margin.left + margin.right + barWidth.value * xitems.value.length;
    });

    const xScale = computed(() => {
      return (
        scaleBand()
          // .domain( props.data.map( d=> d[xColumn.value]))
          .domain(xitems.value)
          .range([margin.left, width.value - margin.right])
          .padding(0.1)
      );
    });

    const yScale = computed(() => {
      let curr = currentData.value.flat().map((d) => d.data);

      return scaleLinear()
        .domain([0, Math.max(...curr.map((d) => +d[yColumn.value]))])
        .rangeRound([height - margin.bottom, margin.top]);
    });

    // const xAxis = ref(null);
    // const yAxis = ref(null);
    // const gx    = ref(null);
    // const gy    = ref(null);

    onMounted(() => {
      // xAxis.value = axisBottom(xScale.value).tickSizeOuter(0);
      // yAxis.value = axisLeft(yScale.value).ticks(null, "s");
      // gx.value    = select(svg.value).append("g").attr("transform", `translate(0, ${height - margin.bottom})`);
      // gy.value    = select(svg.value).append("g").attr("transform", `translate(${margin.left}, 0)`);
      // gx.value.call(xAxis.value);
      // gy.value.call(yAxis.value);
    });

    watch(
      config,
      /*config*/ () => {
        // console.log("config:", config);
        values.value = filters.value.map((d) => d.items[0]);
        updateScales();
      }
    );

    const svg = ref(null);

    const saveImage = async () => {
      const canvas = new OffscreenCanvas(width.value, height);
      const ctx = canvas.getContext("2d");
      const v = await Canvg.fromString(
        ctx,
        svg.value.outerHTML,
        presets.offscreen()
      );

      await v.render();

      const blob = await canvas.convertToBlob();
      const pngUrl = URL.createObjectURL(blob);

      FileSaver.saveAs(pngUrl, "image.png");
    };

    return {
      showTooltip,
      clientX,
      clientY,
      tooltipItem,

      svg,
      containter,
      margin,
      height,
      width,
      barWidth,
      xScale,
      yScale,
      filters,
      values,
      mappedData,
      currentData,
      color,
      background,
      ticks,
      f,

      updateValues,
      onHover,
      onMove,
      saveImage,
      // xAxis
      short: store.getters.translateLabel,
    };
  },
  components: {
    Tooltip,
  },
};
</script>
<template>
  <div class="sg_viz">
    <div class="row">
      <div class="col-12">
        <!-- the filters -->
        <div v-if="filters.length">
          <ul class="row sg_filters">
            <li v-for="(filter, i) of filters" :key="`filter-${i}`" class="col">
              {{ filter.name }}
              <select @change="(e) => updateValues(e, i)">
                <option
                  v-for="(opt, j) of filter.items"
                  :key="`fil-${opt}-${i}-${j}`"
                >
                  {{ opt }}
                </option>
              </select>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12">
        <div class="sg_dataviz" ref="containter">
          <svg
            ref="svg"
            xmlns="http://www.w3.org/2000/svg"
            :style="{ background: background }"
            version="1.2"
            baseProfile="tiny"
            width="100%"
            height="100%"
            :viewBox="`0 0 ${width} ${height}`"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="sg_svg"
          >
            <rect width="100%" height="100%" :fill="background" />
            <transition-group name="slide-fade" tag="g" appear mode="out-in">
              <!-- y ticks -->
              <!-- <g v-for="(tick, i) of yScale.ticks()" :transform="`translate(0, ${yScale(tick)})`" :key="`x-tick-${i}`">
          <line :x1="margin.left" 
                y1="0" 
                :x2="width - margin.right - margin.left + xScale.bandwidth()" 
                y2="0" 
                :stroke="ticks.color"
                :fill-opacity="ticks.opacity" />
        </g> -->

              <rect
                v-for="(d, i) of currentData"
                :key="`bar-${i}-${d.id}`"
                :width="xScale.bandwidth()"
                :height="height - yScale(d.value) - margin.bottom"
                :x="xScale(d.category)"
                :y="yScale(d.value)"
                class="cat-bar"
                fill-opacity="1"
                :fill="color"
                :style="{ fill: color }"
                @mouseover="(e) => onHover(e, d)"
                @mdousemove="onMove"
                @mouseout="showTooltip = false"
              ></rect>
            </transition-group>

            <!-- xScaleAxis -->
            <g :transform="`translate(0, ${height - margin.bottom})`">
              <!-- ticks -->
              <g
                v-for="(tick, i) of xScale.domain()"
                :transform="
                  `translate(${xScale(tick) + xScale.bandwidth() / 2}, 0)`
                "
                :key="`x-tick-${i}`"
              >
                <line x1="0" y1="0" x2="0" :y2="3" stroke="black" />
                <text
                  x="0"
                  y="5"
                  text-anchor="middle"
                  alignment-baseline="hanging"
                  :font-size="ticks.fontSize"
                >
                  {{ short(tick) }}
                </text>
              </g>

              <!-- Axis -->
              <line
                :x1="margin.left"
                y1="0"
                :x2="width - margin.right"
                y2="0"
                stroke="black"
              />
            </g>

            <!-- yScaleAxis -->
            <g :transform="`translate(${margin.left},0)`">
              <!-- ticks -->
              <g
                v-for="(tick, i) of yScale.ticks()"
                :transform="`translate(0, ${yScale(tick)})`"
                :key="`x-tick-${i}`"
              >
                <line
                  :x1="-6"
                  y1="0"
                  :x2="0"
                  y2="0"
                  stroke="black"
                  :fill-opacity="ticks.opacity"
                />
                <text
                  y="0"
                  x="-9"
                  text-anchor="end"
                  alignment-baseline="middle"
                  :font-size="ticks.fontSize"
                >
                  {{ f(tick) }}
                </text>
              </g>

              <!-- Axis -->
              <line
                :x1="0"
                :y1="margin.top"
                :x2="0"
                :y2="height - margin.bottom"
                stroke="black"
              />
            </g>
          </svg>
        </div>
      </div>
      <div class="col-12">
        <p>
          <button @click.prevent="saveImage" class="btn_image">
            Guardar imagen <b class="sg_i_image"></b>
          </button>
        </p>
      </div>
    </div>

    <tooltip
      :clientX="clientX"
      :clientY="clientY"
      :show="showTooltip"
      :labels="config.labels"
      :item="tooltipItem"
    />
  </div>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  fill-opacity: 0;
}
</style>
