<script>
import {ref, computed, onMounted} from "vue";
import {useStore} from "vuex";
// import {select} from "d3-selection";
import {format} from "d3-format"
import { scaleBand , scaleLinear} from "d3-scale";
// import {/*axisBottom,*/ axisLeft} from "d3-axis";
import { line } from "d3-shape";

import FileSaver from "file-saver";

import Canvg, {
    presets
} from 'canvg';

import chroma from "chroma-js";

import CategoryList from '@/components/CategoryListUI.vue';
import Tooltip from "@/components/TooltipUI.vue";

import {linesCategory} from "@/graphsConfig.js";

import getFilters from "@/composables/filters.js";
import getTooltip from "@/composables/tooltip.js";

const {
  colors : colorList,
  xLabel,
  yLabel,
  cLabel,
  height,
  margin,
  barWidth : bw,
  dotRadius, 
  strokeWidth,
  minWidth,
  ticks
} = linesCategory;


const f = format(",")

export default {
  props : ["data", "config"],
  setup(props){
    const store = useStore();
    const containter = ref(null);

    const updateValues = (e, i) => {
      values.value[i] = e.target.value;
      updateScales();
    }

    const updateScales = () => {
      // xAxis.value = axisBottom(xScale.value).tickSizeOuter(0);
      // yAxis.value = axisLeft(yScale.value).ticks(null, "s");
      // gx.value    = select(svg.value).append("g").attr("transform", `translate(0, ${height - margin.bottom})`);
      // gy.value    = select(svg.value).append("g").attr("transform", `translate(${margin.left}, 0)`);
      
      // gx.value.call(xAxis.value);
      // gy.value.call(yAxis.value)
    }

    const {onHover, onMove, showTooltip, clientX, clientY, tooltipItem} = getTooltip();
    const {filters} = getFilters(props);

    const xColumn    = computed( () => props.config.fields.find(d => d.label == xLabel).column );
    const yColumn    = computed( () => props.config.fields.find(d => d.label == yLabel).column );
    const cColumn    = computed( () => props.config.fields.find(d => d.label == cLabel).column );
    const categories = computed( () => [...new Set(props.data.map( d => d[cColumn.value]))].sort() );
    
    const xitems =  computed( () => {
      return [...new Set( props.data.map( d => d[xColumn.value]) )]
    });

    // let barWidth = bw;

    const barWidth = computed( () => {
      const items = xitems.value.length;
      const containerWidth =  containter.value ? containter.value.offsetWidth : 0;
      const minW           = containerWidth > minWidth ? containerWidth : minWidth;
      if(!items) return bw;
      
      // const minWidth = 400;
      const total = items * bw;
      return total > minW ? bw : minW/items;
    });

    const values = ref( filters.value.map( d => d.items[0]) );

    const colors = computed( () => {
      let categories = [...new Set(mappedData.value.map(d => d[cColumn.value]))];
      if(categories.length <= colorList.length){
        return colorList;
      }
      else{
        return chroma.scale(colorList).mode('lch').colors(categories.length);
      }
    });

    const mappedData = computed( () => {
      let filters    = props.config.filters;
      let data = props.data.map(d => {
        let item = {};
        item.id        = new Date().valueOf();
        item.category  = d[xColumn.value];
        item.value     = d[yColumn.value];
        item[cColumn.value]  = d[cColumn.value];
        for(let f of filters){
          item[f] = d[f];
        }
        item.data     = d;
        return item;
      });
      return data;
    })

    const currentData = computed( () => {
      let curr =  mappedData.value.filter(d => {
        let check = [];
        for(let i = 0; i< filters.value.length; i++){
          check.push(d[filters.value[i].name] == values.value[i])
        }
        return check.filter(d => d).length == filters.value.length;
      })

      return categories.value.map( d => curr.filter(e => e[cColumn.value] == d))
    })

    const width  = computed( () => {
      return margin.left + margin.right + (barWidth.value * xitems.value.length)
    });

    const xScale = computed( () => {

      let curr = currentData.value.flat().map(d => d.data);
      return scaleBand()
        // .domain( props.data.map( d=> d[xColumn.value]))
        .domain( curr.map( d=> d[xColumn.value]))
        .range([margin.left, width.value - margin.right])
        .padding(.1)
    });

    const yScale = computed( () => {
      let curr = currentData.value.flat().map(d => d.data);
      return scaleLinear()
        // .domain([ Math.min(... props.data.map( d => +d[yColumn.value])),Math.max(... props.data.map( d => +d[yColumn.value])) ])
        .domain([ Math.min(... curr.map( d => +d[yColumn.value])),Math.max(... curr.map( d => +d[yColumn.value])) ])
        .rangeRound([height - margin.bottom, margin.top])
    });

    const lineFn = computed( () => {
      const lnFn = line()
        .x(d => xScale.value(d.category) + xScale.value.bandwidth()/2)
        .y(d => yScale.value(d.value))
      
      return lnFn;
    });

    // const xAxis = ref(null);
    // const yAxis = ref(null);
    // const gx    = ref(null);
    // const gy    = ref(null);

    onMounted( () => {
      // xAxis.value = axisBottom(xScale.value).tickSizeOuter(0);
      // yAxis.value = axisLeft(yScale.value).ticks(null, "s");
      // gx.value    = select(svg.value).append("g").attr("transform", `translate(0, ${height - margin.bottom})`);
      // gy.value    = select(svg.value).append("g").attr("transform", `translate(${margin.left}, 0)`);
      
      // gx.value.call(xAxis.value);
      // gy.value.call(yAxis.value)
    });

    const svg    = ref(null);

    const saveImage = async () => {
      const canvas = new OffscreenCanvas(width.value, height);
      const ctx    = canvas.getContext('2d');
      const v      = await Canvg.fromString(ctx, svg.value.outerHTML, presets.offscreen());

      await v.render();

      const blob = await canvas.convertToBlob();
      const pngUrl = URL.createObjectURL(blob);

      FileSaver.saveAs(pngUrl, "image.png")
    }

    return {
      showTooltip,
      clientX,
      clientY,
      tooltipItem,

      svg,
      containter,
      margin,
      height,
      width,
      barWidth,
      dotRadius, 
      strokeWidth,
      xScale,
      yScale,
      filters,
      values,
      currentData,
      mappedData,
      categories,
      colors,
      f,
      ticks,

      lineFn,
      updateValues,
      onHover,
      onMove,
      saveImage,
      short : store.getters.translateLabel
    }
  },
  components : {
    Tooltip,
    CategoryList
  }
}
</script>
<template>
  <div class="sg_viz">
    <div class="row mt-4">
    <div class="col-12">
      <!-- the filters -->
      <div v-if="filters.length">
        <ul class="row sg_filters">
          <li v-for="(filter, i) of filters" :key="`filter-${i}`" class="col">
            {{filter.name}}
            <select @change="e => updateValues(e, i)">
              <option v-for="(opt,j) of filter.items" :key="`fil-${opt}-${i}-${j}`">
                {{opt}}
              </option>
            </select>
          </li>
        </ul>
      </div>
      <!-- category list -->
      <div class=" mt-3">
        <category-list :categories="categories" :colors="colors" :current="currentData" />
      </div>  
    </div>
    </div>
    <div class="sg_dataviz" ref="containter">
    <svg ref="svg" xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny" width="100%" height="100%" :viewBox="`0 0 ${width} ${height}`" stroke-linecap="round" stroke-linejoin="round"  class="sg_svg">
      
      
      <!-- xScaleAxis -->
      <g :transform="`translate(0, ${height - margin.bottom})`">
        <!-- ticks -->
        <g v-for="(tick, i) of xScale.domain()" :transform="`translate(${xScale(tick) + xScale.bandwidth()/2}, 0)`" :key="`x-tick-${i}`">
          <line x1="0" y1="0" x2="0" :y2="3" stroke="black" />
          <text x="0" y="5" text-anchor="middle" alignment-baseline="hanging" :font-size="ticks.fontSize">{{short(tick)}}</text>
        </g>
        
        <!-- Axis -->
        <line
          :x1="margin.left"
          y1="0"
          :x2="width - margin.right"
          y2="0"
          stroke="black" />
      </g>


      <!-- yScaleAxis -->
      <g :transform="`translate(${margin.left},0)`">
        <!-- ticks -->
        <g v-for="(tick, i) of yScale.ticks()" :transform="`translate(0, ${yScale(tick)})`" :key="`x-tick-${i}`">
          <line :x1="-6" 
                y1="0" 
                :x2="0" 
                y2="0" 
                stroke="black"
                :fill-opacity="ticks.opacity" />
          <text y="0" x="-9" text-anchor="end" alignment-baseline="middle" :font-size="ticks.fontSize">{{f(tick)}}</text>
        </g>

        <!-- Axis -->
        <line
          :x1="0"
          :y1="margin.top"
          :x2="0"
          :y2="height - margin.bottom"
          stroke="black" />
      </g>


      <g v-for="(data, j) of currentData" :key="`lines-graph-${j}-${config.id}`">
        <path fill="none" :stroke="colors[j]" :stroke-width="strokeWidth" stroke-linejoin="round" stroke-linecap="round" :d="lineFn(data)" />
        <circle v-for="(d, i) of data" :key="`bar-${i}-${d.id}`" 
          :r="dotRadius"
          :cx="xScale(d.category) + xScale.bandwidth()/2"
          :cy="yScale(d.value)"
          :fill="colors[j]"
          @mouseover="e => onHover(e, d)" 
          @mousemove="onMove" 
          @mouseout="showTooltip = false">
        </circle>
      </g>
    </svg>
    </div>
    <p class="mt-3"><button @click.prevent="saveImage" class="btn_image">Guardar imagen <b class="sg_i_image"></b></button></p>

    <tooltip  :clientX="clientX" :clientY="clientY" :show="showTooltip" :labels="config.labels" :item="tooltipItem" />
  </div>
</template>