import config from "../../graph.conf.json";

const mapConfig = config.find((d) => d.id == 1);
const stackConfig = config.find((d) => d.id == 2);
const barsConfig = config.find((d) => d.id == 3);
const lineConfig = config.find((d) => d.id == 4);
const linesConfig = config.find((d) => d.id == 5);

export const mapCategory = {
  colors: ["#9F2241", "#235B4E", "#DDc9A3"],
  // colors: ["#E09841", "#41AEE0", "#1C6E94"],
  noDataColor: "6F7271",
  // noDataColor: "grey",
  locationCol: mapConfig.fields[0],
  categoryCol: mapConfig.fields[1],
  width: 800,
  height: 1025,
};

export const barsCategory = {
  height: 1000,
  margin: {
    top: 10,
    bottom: 40,
    left: 30,
    right: 10,
  },
  barWidth: 50,
  minWidth: 1400,
  xLabel: barsConfig.fields[0],
  yLabel: barsConfig.fields[1],
  color: "#235B4E",
  // color: "#E09841",
  background: "white",
  ticks: {
    color: "6F7271",
    // color: "grey",
    opacity: 0.1,
    fontSize: "1.3em",
  },
};

export const stackedBarsCategory = {
  height: 500,
  margin: {
    top: 10,
    bottom: 20,
    left: 50,
    right: 10,
  },
  barWidth: 50,
  minWidth: 400,
  xLabel: stackConfig.fields[1],
  yLabel: stackConfig.fields[2],
  cLabel: stackConfig.fields[0],
  colors: ["#9F2241", "#235B4E", "#DDc9A3"],
  // colors: ["#E09841", "#41AEE0", "#1C6E94"],
  ticks: {
    color: "6F7271",
    // color: "grey",
    opacity: 0.1,
    fontSize: "1.3em",
  },
};

export const lineCategory = {
  height: 800,
  margin: {
    top: 10,
    bottom: 40,
    left: 30,
    right: 10,
  },
  barWidth: 50,
  minWidth: 400,
  dotRadius: 4,
  dotFill: "#235B4E",
  // dotFill: "#41AEE0",
  strokeWidth: 3,
  stroke: "#235B4E",
  // stroke: "#41AEE0",
  xLabel: lineConfig.fields[0],
  yLabel: lineConfig.fields[1],
  ticks: {
    color: "6F7271",
    // color: "grey",
    opacity: 0.1,
    fontSize: "1.3em",
  },
};

export const pyramidCategory = {
  width: 800,
  margin: {
    top: 10,
    bottom: 20,
    left: 100,
    right: 10,
  },
  barHeight: 30,
  colors: ["#DDc9A3", "#235B4E"],
  // colors: ["#E09841", "#41AEE0"],
  background: "white",
  xLabel: "eje x",
  yLabel: "eje y",
  cLabel: "categoría principal",
  ticks: {
    color: "6F7271",
    // color: "grey",
    opacity: 0.1,
    fontSize: "1.3em",
  },
};

export const linesCategory = {
  colors: ["#9F2241", "#235B4E", "#DDc9A3", "#98989A"],
  // colors: ["#E09841", "#41AEE0", "#1C6E94", "#94570D"],
  height: 800,
  margin: {
    top: 10,
    bottom: 40,
    left: 30,
    right: 10,
  },
  barWidth: 50,
  dotRadius: 4,
  strokeWidth: 3,
  minWidth: 400,
  xLabel: linesConfig.fields[0],
  yLabel: linesConfig.fields[1],
  cLabel: linesConfig.fields[2],
  ticks: {
    color: "6F7271",
    // color: "grey",
    opacity: 0.1,
    fontSize: "1.3em",
  },
};
