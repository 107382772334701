import {computed} from "vue";
export default function getFilters(props){
  
  const getFilterOptions = filter => {
    return [...new Set(props.data.map( d => d[filter]))].sort()
  }

  const filters = computed( () => {
    return props.config.filters.map(d => {
      return {
        name : d,
        items : getFilterOptions(d)
      }
    }) 
  });

  return {filters}
}